//
//
//
//
//
//
//
//
//

import colors from '@/shared/assets/scss/colorsExport.scss';

export default {
  name: 'IconBase',
  props: {
    name: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: colors.black,
    },
  },
  created() {
    this.$options.components[this.name] = () => import(`./${this.name}.vue`);
  },
};
