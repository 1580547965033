//
//
//
//
//
//
//
//
//

import colors from '../assets/scss/colorsExport.scss';

export default {
  name: 'FCCard',
  props: {
    bgColor: { type: String, default: colors.white },
    noShadow: { type: Boolean, default: false },
    noRadius: { type: Boolean, default: false },
  },
  computed: {
    cardStyles() {
      return {
        'no-shadow': this.noShadow,
        'no-radius': this.noRadius,
      }
    }
  }
};
