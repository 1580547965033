//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCCard from '@/shared/components/FCCard.vue';

export default {
  name: 'FCCardWithTabs',
  components: {
    FCCard,
  },
  props: {
    tabsContent: { type: Array, default: () => [{ title: '', content: '' }] },
  },
  data: () => ({
    active: 0,
  }),
  computed: {
    content() {
      return this.tabsContent[this.active];
    },
  },
  methods: {
    activate(index) {
      this.active = index;
    },
    getElementName(title) {
      return `${this.$options.name}_${title}`;
    },
  },
};
