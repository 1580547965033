//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FCCardWithTabs from '@/shared/components/FCCardWithTabs.vue';
import colors from '@/shared/assets/scss/colorsExport.scss';
import IconBase from '~/components/icons/IconBase.vue';

export default {
  name: 'DetailsTabs',
  components: {
    FCCardWithTabs,
    IconBase,
  },
  data() {
    return {
      colors,
    };
  },
  props: {
    tabs: { type: Array, default: () => ([]) },
  },
  methods: {
    showCheckmarks(title) {
      const tabsWithCheckmarks = ['Who Is This For?', 'About The Trackers'];
      return tabsWithCheckmarks.includes(title);
    },
  },
};
